<template>
    <div :class="dir=='left'?'coldivYellowLeft':'coldivYellowRight'" >
		<slot></slot>
	</div>
</template>

<style lang="less" scoped>
.coldivYellowLeft {
	width: 21.875vw;
	height:100%;
	background-image: url('~@/assets/common/背景黄左.png');
	background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

.coldivYellowRight {
	width: 21.875vw;
	height:100%;
	background-image: url('~@/assets/common/背景黄右.png');
	background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}
</style>

<script>
	export default {
		props:{
			direction:{
				type: String
			}
		},
		data(){
			return {
				dir: 'left'
			}
		},
		mounted(){
			if(this.direction == 'right'){
				this.dir = this.direction;
			}
			return false;
		}
	}
</script>

